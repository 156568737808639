import React from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';

import { convertKeysToCamelCase } from 'helpers/baseHelper';
import useHeader from 'hooks/useHeader';
import usePartners from 'hooks/usePartners';
import style from 'assets/scss/components/Header.module.scss';
import useGeneral from '../hooks/useGeneral';

function isPartnersPage(translation) {
  return translation === 'partners';
}

function HeaderLinks() {
  const { t } = useTranslation();
  const {
    headerLinksItem,
    headerLinksActive,
    headerLinksPartnerList,
    headerLinksPartnerListItem,
  } = convertKeysToCamelCase(style);
  const { headerLinks, pathname } = useHeader();
  const { handleRedirect } = useGeneral();
  const { companies } = usePartners();

  function togglePartnerList(show) {
    const partnerLists = document.getElementsByClassName(headerLinksPartnerList);
    for (let i = 0; i < partnerLists.length; ++i) {
      let partnerList = partnerLists[i];
      partnerList.style.display = 'none';
      if (show) {
        partnerList.style.display = 'block';
      }
    }
  }

  return (
    <>
      {headerLinks.map((link) => {
        const { translation, url } = link;
        return (
          <span
            onClick={() => {
              if (!isPartnersPage(translation)) {
                handleRedirect(url);
              } else {
                togglePartnerList(true);
              }
            }}
            onMouseEnter={(e) => {
              if (isPartnersPage(translation)) {
                togglePartnerList(isPartnersPage(translation));
              }
            }}
            onMouseLeave={(e) => {
              if (isPartnersPage(translation)) {
                togglePartnerList(false);
              }
            }}
            key={nanoid()}
            className={pathname.includes(url) ? headerLinksActive : ''}
          >
            <span>
              <span className={headerLinksItem}>{t(`header.links.${translation}`)}</span>
              {isPartnersPage(translation) && (
                <>
                  <span></span>
                  <i className="icon icon-down-open-big margin-left-10" />
                  <div id="partnerList" className={headerLinksPartnerList}>
                    {companies?.map((company) => {
                      const { name } = company;
                      return (
                        <p
                          key={nanoid()}
                          onClick={() => handleRedirect(url.replace(':id', company.id))}
                          className={headerLinksPartnerListItem}
                        >
                          {name}
                        </p>
                      );
                    })}
                  </div>
                </>
              )}
            </span>
          </span>
        );
      })}
    </>
  );
}

export default HeaderLinks;
